import React, { useState } from "react";
import { Modal, Typography } from "@material-ui/core";

import { useStore } from "../../../store";
import { Button } from "../../index";

import useStyles from "./styles";
import { checkStockProduct } from "../../../services";

export interface VisitorPaymentMethodModalProps {
  open: boolean;
  isVariablePrice?: boolean;
  onClose?: () => void;
}

export const VisitorPaymentMethodModal: React.FC<VisitorPaymentMethodModalProps> = ({ open, isVariablePrice = false, onClose }) => {
  const Styles = useStyles();

  const { navigation, order } = useStore();
  const [showOutOfStockMessage, setShowOutOfStockMessage] = useState(false);

  const handleManualPurchase = async (type: string) => {
    const stockResults = await Promise.all(order.items.map((o) => checkStockProduct(o.id)));
    if (stockResults.every((hasStock) => hasStock)) {
      navigation.navigateTo("/confirm-manual-purchase", {
        state: {
          type,
          isVariablePrice,
        },
      });
    } else {
      setShowOutOfStockMessage(true);
    }
  };

  return (
    <Modal className={Styles.modal} open={open} onClose={onClose}>
      { showOutOfStockMessage ? (
        <>
          <div className={Styles.modalView}>
            <Typography component="span" className={Styles.message}>
              Olá! Um ou mais produtos no seu carrinho não estão mais disponíveis.
            </Typography>
            <div className={Styles.actions}>
              <Button onClick={() => {
                if (onClose) onClose();
                setShowOutOfStockMessage(false);
                return null;
              }}
              >
                OK
              </Button>
            </div>
          </div>

        </>
      ) : (
        <>
          <div className={Styles.modalView}>
            <Typography component="span" className={Styles.title}>
              Finalizar Compra
            </Typography>

            <Typography component="span" className={Styles.message}>
              Escolha o método de pagamento
            </Typography>

            <div className={Styles.actions}>
              <Button
                onClick={() => handleManualPurchase('visitor')}
              >
                Pagar com pix
              </Button>

            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
