import {
  isToday,
} from "date-fns";

import api from "../configs/api";

import {
  Items,
  KitchenOrder,
  KitchenOrders, NewOrder,
  NewOrderIndividual,
} from "../types";

export const createOrder = async (newOrder: NewOrder) => {
  try {
    const { status, data } = await api.post("/client-order", newOrder);

    if (status !== 201) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const createOrderIndividual = async (newOrder: NewOrderIndividual) => {
  try {
    const { status, data } = await api.post("/virtual-card/payment", newOrder);

    if (status !== 201) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const getTodaysOrdersPerUser = async (user: string): Promise<KitchenOrders> => {
  const { status, data } = await api.get(
    `/client-order/${user}`
  );

  if (status !== 200) throw new Error();

  const orders = data
    .filter((order: any) => isToday(new Date(order.createdAt)))
    .map((order: any) => {
      const {
        clientName,
        items,
        virtualCard,
        isOnCredit,
        ...rest
      } = order;

      const _items = items.map((item: any) => ({
        ...item.product,
        maxAmount: item.product.quantity,
        quantity: item.quantity,
        price: item.totalAmount,
      }));

      const client = !isOnCredit
        ? virtualCard?.client
        : {
          name: clientName,
          picture: null,
          id: null,
        };

      return {
        ...rest,
        client,
        items: _items,
      } as KitchenOrders;
    });
  return orders;
};

export const getTodayScheduledOrders = async (user: string): Promise<KitchenOrders> => {
  const { status, data } = await api.get(
    `/client-order/schedule/${user}`
  );

  if (status !== 200) throw new Error();

  const orders = data
    .filter((order: any) => isToday(new Date(order.scheduleFor)))
    .map((order: any) => {
      const {
        clientName,
        items,
        virtualCard,
        isOnCredit,
        ...rest
      } = order;

      const _items = items.map((item: any) => ({
        ...item.product,
        maxAmount: item.product.quantity,
        quantity: item.quantity,
        price: item.totalAmount,
      }));

      const client = !isOnCredit
        ? virtualCard?.client
        : {
          name: clientName,
          picture: null,
          id: null,
        };

      return {
        ...rest,
        client,
        items: _items,
      } as KitchenOrders;
    });
  return orders;
};

export const deliverOrder = async (id: string): Promise<boolean> => {
  try {
    const { status } = await api.patch(`/client-order/${id}`, {
      isDelivered: true,
      isCanceled: false,
      isPending: false,
      isInProgress: false,
    });

    if (status !== 200) throw new Error();

    return true;
  } catch (error) {
    return false;
  }
};

export const cancelOrder = async (id: string, items: Items): Promise<boolean> => {
  try {
    const { status } = await api.patch(`/client-order/items/${id}`, {
      items
    });

    if (status !== 200) throw new Error();

    return true;
  } catch (error) {
    return false;
  }
};

export const progressOrder = async (id: string): Promise<KitchenOrder> => {
  const { status, data } = await api.patch(`/client-order/${id}`, {
    isDelivered: false,
    isCanceled: false,
    isPending: false,
    isInProgress: true,
  });

  if (status !== 200) throw new Error();

  return data;
};
