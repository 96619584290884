import { useState, useMemo } from "react";

import { Item, ItemArray, Order } from "../../types";
import sumValuesFromAnArray from "../../utils/sumValuesFromAnArray";

export interface OrderInterfaceDefault {
  addItem: (item: Item, isOnCredit: boolean) => void;
  removeItem: (id: string) => void;
  editQuantity: (id: string, amount: number) => void;
  setFinalDataIsOnCreditOrder: (_clientSignature: string, _clientName: string, _clientId?: string, _adminClientName?: string, _adminClientEmail?: string, _adminClientPhone?: string) => void;
  setFinalDataIsManualOrder: (_clientName: string) => void;
  cleanItems: () => void;
  reset: () => void;
}

export type OrderInterface = Order & OrderInterfaceDefault;

export function useOrder() {
  const [items, setItems] = useState<ItemArray>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isOnCredit, setIsOnCredit] = useState<boolean | undefined>();
  const [clientSignature, setClientSignature] = useState<string | undefined>();
  const [clientName, setClientName] = useState<string | undefined>();
  const [clientId, setClientId] = useState<string | undefined>();
  const [adminClientName, setAdminClientName] = useState<string | undefined>();
  const [adminClientEmail, setAdminClientEmail] = useState<string | undefined>();
  const [adminClientPhone, setAdminClientPhone] = useState<string | undefined>();

  const changeTotalAmountValue = (_items: ItemArray) =>
    setTotalAmount(
      sumValuesFromAnArray(
        _items.map((item) => (item.price) * (item.amount || 0))
      )
    );

  const addItem = (item: Item, _isOnCredit: boolean) => {
    setIsOnCredit(_isOnCredit);
    setItems((value) => {
      if (value.some((i) => i.id === item.id)) {
        value.map((i) => {
          const currentItem = i;
          if (currentItem.id === item.id) {
            currentItem.amount += item.amount;
            currentItem.totalAmount = currentItem.amount * currentItem.price;
          }

          return currentItem;
        });
      } else {
        const _item = item;

        _item.totalAmount = _item.amount * _item.price;
        value.push(_item);
      }

      changeTotalAmountValue(value);

      return value;
    });
  };

  const removeItem = (id: string) => {
    setItems((value) => {
      const currentValue = value.filter((item) => item.id !== id);

      changeTotalAmountValue(currentValue);

      return currentValue;
    });
  };

  const editQuantity = (id: string, amount: number) => {
    setItems((value) => {
      const currentValue = value.map((item) => {
        const currentItem = item;
        if (currentItem.id === id) {
          currentItem.amount = amount;
          currentItem.totalAmount = currentItem.price * amount;
        }

        return currentItem;
      });

      changeTotalAmountValue(currentValue);

      return currentValue;
    });
  };

  const setFinalDataIsOnCreditOrder = (_clientSignature: string, _clientName: string, _clientId?: string, _adminClientName?: string, _adminClientEmail?: string, _adminClientPhone?: string) => {
    setClientSignature(_clientSignature);
    setClientName(_clientName);
    setClientId(_clientId);
    setAdminClientName(_adminClientName);
    setAdminClientEmail(_adminClientEmail);
    setAdminClientPhone(_adminClientPhone);
  };

  const setFinalDataIsManualOrder = (_clientName: string) => {
    setClientName(_clientName);
  };

  const cleanItems = () => setItems([]);

  const reset = () => {
    cleanItems();

    setTotalAmount(0);
    setIsOnCredit(undefined);
    setClientSignature(undefined);
    setClientName(undefined);
    setClientId(undefined);
    setAdminClientName(undefined);
    setAdminClientEmail(undefined);
    setAdminClientPhone(undefined);
  };

  return useMemo(
    () => ({
      items,
      totalAmount,
      isOnCredit,
      clientSignature,
      clientName,
      clientId,
      adminClientName,
      adminClientEmail,
      adminClientPhone,
      addItem,
      removeItem,
      editQuantity,
      setFinalDataIsOnCreditOrder,
      setFinalDataIsManualOrder,
      cleanItems,
      reset,
    }),
    [items, totalAmount, isOnCredit, clientSignature, clientName, clientId, adminClientName, adminClientEmail, adminClientPhone, addItem, removeItem, editQuantity, setFinalDataIsOnCreditOrder, setFinalDataIsManualOrder, cleanItems, reset]
  );
}
