import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { IconButton, Popper } from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { Client } from '../../types';
import useStyles from "./styles";

interface CustomFieldProps {
  label: string;
  placeholder?: string;
  helper?: string;
  error?: string;
  success?: string;
  type?: "text" | "password";
  focused?: boolean;
  options?: Array<Client>;
  setValue?: (value: any) => void;
}

export type FieldProps = CustomFieldProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const Field: React.FC<FieldProps> = ({
  label,
  error,
  success,
  className,
  options,
  value,
  onChange,
  helper = "",
  type = "text",
  placeholder = "",
  focused = false,
  setValue,
  ...rest
}) => {
  const Styles = useStyles();

  const fieldRef = useRef<HTMLInputElement>(null);

  const [showValue, setShowValue] = useState<boolean>(true);

  const [search, setSearch] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);

  const getHelperText = (): string => {
    if (error) return error;

    if (success) return success;

    return helper;
  };

  const normalizeString = (text: string): string => text
    .trim()
    .toLocaleLowerCase();

  const getTypeInput = useCallback((): string | undefined => {
    if (type === "password") {
      if (showValue) return "text";

      return "password";
    }

    return "text";
  }, [showValue]);

  const filteredOptions = options?.filter((item) => normalizeString(item.name).includes(normalizeString(search)));

  const handleItemClick = useCallback((_value: string, id?: string) => {
    setSearch(_value);
    if (setValue) {
      setValue(id);
    }
    if (fieldRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
      nativeInputValueSetter?.call(fieldRef.current, _value);

      const inputEvent = new Event('input', { bubbles: true });
      fieldRef.current.dispatchEvent(inputEvent);
    }

    setAnchorEl(null);
  }, [fieldRef.current?.value]);

  const cleanInputValue = () => {
    if (fieldRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
      nativeInputValueSetter?.call(fieldRef.current, "");

      const inputEvent = new Event('input', { bubbles: true });
      fieldRef.current.dispatchEvent(inputEvent);
    }
  };

  useEffect(() => {
    if (type === "password") setShowValue(false);
  }, [type]);

  useEffect(() => {
    if (!search) cleanInputValue();
    else if (!options?.some((item) => item.name === search)) cleanInputValue();
  }, [search]);

  return (
    <div className={`${className ? `${className} ` : ""}${Styles.fieldView}`}>
      <span className={Styles.label}>{label}</span>

      {
        options && type !== "password" && (
          <>
            <input
              className={`${Styles.field}${focused ? ` ${Styles.focused}` : ""}${
                success ? ` ${Styles.successField}` : ""
              }${error ? ` ${Styles.errorField}` : ""}`}
              placeholder={placeholder}
              autoComplete="off"
              value={search}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
              onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => setAnchorEl(event.currentTarget)}
            />

            <Popper
              anchorEl={anchorEl}
              open={!!anchorEl && !!search}
              placement="bottom-start"
            >
              <div className={Styles.popperPaper}>
                {
                  filteredOptions && filteredOptions?.length > 0
                    ? filteredOptions?.map((item) => (
                      <button
                        type="button"
                        key={item.email}
                        className={Styles.item}
                        onClick={() => {
                          handleItemClick(item.name, item.id);
                        }}
                      >
                        <span className={Styles.itemLabel}>{item.name}</span>

                        <span className={Styles.itemAlternativeLabel}>{item.email.replace(/.*?(([0-9]*\.)?[0-9]+).*/g, "$1")}</span>
                      </button>
                    ))
                    : (
                      <div className={Styles.item}>
                        <span className={Styles.itemLabel}>Nenhum Resultado Encontrado</span>
                      </div>
                    )
                }
              </div>
            </Popper>
          </>
        )
      }

      <input
        ref={fieldRef}
        className={`${Styles.field}${options ? ` ${Styles.hiddenField}` : ''}${focused ? ` ${Styles.focused}` : ""}${
          success ? ` ${Styles.successField}` : ""
        }${error ? ` ${Styles.errorField}` : ""}`}
        type={getTypeInput()}
        placeholder={placeholder}
        autoComplete={type === "password" ? "on" : ""}
        value={value}
        onChange={onChange}
        {...rest}
      />

      {(error || success || helper) && !anchorEl && (
        <span
          className={`${Styles.helper}${
            success ? ` ${Styles.successHelper}` : ""
          }${error ? ` ${Styles.errorHelper}` : ""}`}
        >
          {getHelperText()}
        </span>
      )}

      {type === "password" && (
        <div className={Styles.visibility}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setShowValue(!showValue)}
          >
            {showValue ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </IconButton>
        </div>
      )}
    </div>
  );
};
