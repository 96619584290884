import React from "react";
import { Control, Controller } from "react-hook-form";

import { Field, FieldProps } from "../index";

export interface FormFieldProps {
  control: Control<any>;
  name: string;
  focus?: (name: string) => boolean;
  onChange?: (value: any) => void;
  setValue?: (value: any) => void;
  hidden?: boolean;
}

export const FormField: React.FC<FieldProps & FormFieldProps> = ({
  name,
  control,
  label,
  focus = () => false,
  hidden,
  setValue,
  ...rest
}) => (
  <div hidden={hidden}>
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Field
          {...rest}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          error={error?.message}
          focused={focus(name)}
          setValue={setValue}
        />
      )}
    />
  </div>
);

export const FormFieldTwoFactor: React.FC<FieldProps & FormFieldProps> = ({
  name,
  control,
  label,
  focus = () => false,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange }, fieldState: { error } }) => {
      const charArray = value ? value.trim().split(' ') : [];
      const showValue = new Array(charArray.length + 1).join("*");
      return (
        <Field
          {...rest}
          type="text"
          label={label}
          name={name}
          value={showValue}
          onChange={onChange}
          error={error?.message}
          focused={focus(name)}
        />
      );
    }}
  />
);
