import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Slide, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, {
  useCallback, useEffect, useRef, useState
} from "react";

import { useForm } from "react-hook-form";

import QRCode from "react-qr-code";

import {
  AdminConfigIcon,
  BackspaceIcon,
  LogoutIcon,
  QrCodeIcon,
  ProfileIcon,
} from "../../assets";
import {
  Button,
  FormField,
  FormFieldTwoFactor,
  LoadingModal,
} from "../../components";

import { LoginUserSchema, LoginUserSchemaInterface } from "../../schemas";

import { loginFaceRecognition, loginTotem } from "../../services";

import { useStore } from "../../store";

import { generateTwoFactorArray } from "../../utils/arrayUtils";

import useStyles from "./styles";
import { useUserManager } from "../../store/hooks";
import { onQRCodeLogin } from "../../services/firebaseRealTimeDatabase";
import {
  getClientById,
  getWalletByClient,
  getTotemClients,
} from "../../services/totem";
import { Client, Wallet } from "../../types";
import { UserListing } from "../../components/UserListing";
import { FaceRecognition } from "../../components/FaceRecognition";
import { useGetEntity } from "../../api/orders/hooks";

type Fields = keyof LoginUserSchemaInterface;

const initialLoginFormValues: LoginUserSchemaInterface = {
  username: "",
  password: "",
};

const numbersUser: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const LoginUser: React.FC = () => {
  const Styles = useStyles();

  const { userManager, userTotem, navigation } = useStore();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm<LoginUserSchemaInterface>({
    mode: "onChange",
    resolver: yupResolver(LoginUserSchema),
    defaultValues: initialLoginFormValues,
  });

  const [focusedField, setFocusedField] = useState<Fields | null>(null);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);

  const [qrCodeModalOpen, setQrCodeModalOpen] = useState<boolean>(false);

  const [faceCheckLoading, setFaceCheckLoading] = useState<boolean>(false);

  const [facialRecognitionModalOpen, setFacialRecognitionModalOpen] =
    useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [loginError, setLoginError] = useState<string | null>(null);

  const [clients, setClients] = useState<Client[]>([]);

  const [showUserList, setShowUserList] = useState<boolean>(true);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const keyboardArray = useRef<any>();

  const handleOnFocusField = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    setFocusedField(event.target.name as Fields);
    setLoginError(null);
    clearErrors();
    event.target.blur();
  };

  // Toda vez que o usuário escolher logar de forma diferente, o keyboard some.
  // Os campos de usuário e senha são limpos.
  // O cliente selecionado é limpo.
  useEffect(() => {
    setFocusedField(null);
    setValue("username", "");
    setValue("password", "");
    setSelectedClient(null);
  }, [showUserList]);

  const handleUserListClick = (client: any) => {
    setSelectedClient(client);
    const username = client?.email.split("@")[0];
    setValue("username", username, { shouldValidate: true });
    setFocusedField("password");
  };

  useEffect(() => {
    if (!selectedClient) {
      setValue("username", "");
      setValue("password", "");
      setFocusedField(null);
    }
  }, [selectedClient]);

  const handleNumberUserClick = useCallback(
    (number: string) => {
      if (focusedField) {
        const value = getValues()[focusedField] || "";

        if (value.length < 6) {
          setValue(focusedField, value + number, { shouldValidate: true });
        }
      }
    },
    [focusedField]
  );

  const handleNumberClick = useCallback(
    (numbers: Array<number>) => {
      if (focusedField) {
        const value = getValues()[focusedField] || "";

        if (value.length < 14) {
          // esse espaço entre value e numbers é importante para o split depois, não remover
          setValue(focusedField, `${value} ${numbers.toString()}`, {
            shouldValidate: true,
          });
        }
      }
    },
    [focusedField]
  );

  const handleBackspace = useCallback(() => {
    if (focusedField) {
      const value = getValues()[focusedField];

      setValue(focusedField, value.slice(0, -1), { shouldValidate: true });
    }
  }, [focusedField]);

  const handleBackspacePassword = useCallback(() => {
    if (focusedField) {
      setValue(focusedField, "", { shouldValidate: true });
    }
  }, [focusedField]);

  const handleLogout = () => {
    navigation.navigateTo("/login-admin", {
      state: {
        isLogount: true,
      },
    });
  };

  // Função para salvar mensagens de validação no localStorage
  const saveValidationMessages = (messages: string) => {
    localStorage.setItem("validationMessages", JSON.stringify(messages));
  };

  const onSubmit = async (data: LoginUserSchemaInterface) => {
    const { username, password } = data;

    const numbersPairs = password
      .trim()
      .split(" ")
      .map((item) => item.split(","));

    const interval = setInterval(() => {
      setProgress((value) => value + 1);
    }, 120);

    const res = await loginTotem(username, numbersPairs);

    if (res) {
      userTotem.login(res);

      navigation.navigateTo("/manual-purchase");

      setLoginError(null);
    } else {
      saveValidationMessages("Usuário ou senha inválidos");
      window.location.reload();
    }

    reset(
      {
        ...initialLoginFormValues,
      },
      {
        keepErrors: true,
      }
    );
    setProgress(0);
    clearInterval(interval);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowKeyboard(!!focusedField);
    }, 500);
  }, [focusedField]);

  useEffect(() => {
    if (!keyboardArray.current || keyboardArray.current.length === 0) {
      keyboardArray.current = generateTwoFactorArray();
    }
  }, []);

  const performLogin = async (clientId: any) => {
    setQrCodeModalOpen(false);
    if (clientId && clientId.client) {
      const res = await getClientById(clientId.client);

      if (res) {
        res.wallet = (await getWalletByClient(clientId.client)) as Wallet;

        userTotem.login(res);

        if (res.role !== "adminClient" && res.role !== "staff") {
          navigation.navigateTo("/manual-purchase");
        } else {
          navigation.navigateTo("/individual-purchase");
        }
      }
    }
  };

  useEffect(() => {
    // Função para recuperar e limpar as mensagens de validação do localStorage
    const getAndClearValidationMessages = () => {
      const messages = localStorage.getItem("validationMessages");
      localStorage.removeItem("validationMessages"); // Remove os dados do localStorage
      return messages ? JSON.parse(messages) : null;
    };

    // Verifica se existem dados salvos no localStorage ao iniciar a página
    const messageErr = getAndClearValidationMessages();
    if (messageErr) {
      setLoginError(messageErr);
      setError("password", {
        type: "value",
        message: " ",
      });
      setError("username", {
        type: "value",
        message: " ",
      });
    }
  }, []);

  const totemEntity = userManager.entity;

  const { data: entity } = useGetEntity(totemEntity, !!totemEntity);

  useEffect(() => {
    if (totemEntity) {
      getTotemClients(totemEntity).then((totemClients) => {
        if (totemClients) {
          setClients(totemClients);
        }
      });
    }
  }, [totemEntity]);

  const deviceId = localStorage.getItem("MenuPass@TotemHash") as string;

  useEffect(() => {
    onQRCodeLogin(userManager.entity, userManager.id, deviceId, performLogin);
  }, []);

  const onFaceLogin = async (image: string) => {
    console.log('onFaceLogin -> image', image);
    setFaceCheckLoading(true);

    const res = await loginFaceRecognition({ image, entity: userManager.entity });
    if (res) {
      userTotem.login(res);
      setFaceCheckLoading(false);

      navigation.navigateTo("/manual-purchase");

      setLoginError(null);
    } else {
      saveValidationMessages("Usuário ou senha inválidos");
      setFaceCheckLoading(false);
      window.location.reload();
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.formView}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className={Styles.title}>Bem-Vindo!</Typography>
          <UserListing
            UserList={clients}
            hidden={!showUserList}
            setSelectedClient={handleUserListClick}
            selectedClient={selectedClient}
          />

          <FormField
            control={control}
            name="username"
            label="Usuário"
            autoFocus
            placeholder="Insira seu Login de usuário"
            onFocus={handleOnFocusField}
            focus={(name) => name === focusedField}
            hidden={showUserList}
          />

          <FormFieldTwoFactor
            control={control}
            name="password"
            label="Senha"
            placeholder="Insira sua Senha"
            type="password"
            onFocus={handleOnFocusField}
            focus={(name) => name === focusedField}
          />

          <Typography className={Styles.errorHelper}>{loginError}</Typography>

          <Button type="submit" size="large" fullWidth>
            Entrar
          </Button>
        </form>

        <div className={Styles.link}>
          <Typography
            component="span"
            onClick={() => setShowUserList((prev) => !prev)}
          >
            {showUserList ? "Entrar com PIN" : "Entrar com a lista de usuários"}
          </Typography>
        </div>

        <div
          className={Styles.link}
          onClick={() => navigation.navigateTo("/visitor-purchase")}
        >
          <ProfileIcon />

          <Typography component="span">Entrar como visitante</Typography>
        </div>

        <div className={Styles.link} onClick={() => setQrCodeModalOpen(true)}>
          <QrCodeIcon />

          <Typography component="span">Login com QR-Code</Typography>
        </div>
        {entity && entity.faceIdentityEnabled && (
          <div className={Styles.link} onClick={() => setFacialRecognitionModalOpen(true)}>
            <ProfileIcon />

            <Typography component="span">
              Login com reconhecimento facial
            </Typography>
          </div>
        )}
      </div>

      {focusedField === "username" && !showUserList && (
        <Slide in={showKeyboard} direction="left">
          <div className={Styles.keyboard}>
            {numbersUser.map((number: string) => (
              <div
                key={number}
                className={Styles.button}
                onClick={() => handleNumberUserClick(number)}
              >
                <Typography component="span">{number}</Typography>
              </div>
            ))}

            <div
              className={`${Styles.button}`}
              onClick={() => handleNumberUserClick("0")}
            >
              <Typography component="span">0</Typography>
            </div>

            <div className={Styles.button} onClick={handleBackspace}>
              <BackspaceIcon />
            </div>
          </div>
        </Slide>
      )}

      {focusedField === "password" && (
        <Slide in={showKeyboard} direction="left">
          <div className={Styles.keyboardPassword}>
            {keyboardArray.current?.map((numbers: Array<number>) => (
              <div
                key={numbers[0]}
                className={Styles.buttonPassword}
                onClick={() => handleNumberClick(numbers)}
              >
                <Typography component="span">{`${numbers[0]} ou ${numbers[1]}`}</Typography>
              </div>
            ))}

            <div
              className={Styles.buttonPassword}
              onClick={handleBackspacePassword}
            >
              <BackspaceIcon />
            </div>
          </div>
        </Slide>
      )}

      {progress > 0 && <LoadingModal open={!!progress} progress={progress} />}

      <QrCodeModal
        open={qrCodeModalOpen}
        onClose={() => setQrCodeModalOpen(false)}
      />

      <FacialRecognitionModal
        open={facialRecognitionModalOpen}
        onClose={() => setFacialRecognitionModalOpen(false)}
        onFaceLogin={onFaceLogin}
        faceCheckLoading={faceCheckLoading}
      />

      <div className={Styles.adminActions}>
        <div
          className={Styles.action}
          onClick={() => navigation.navigateTo("/manual-purchase")}
        >
          <AdminConfigIcon />
        </div>

        <div className={Styles.action} onClick={handleLogout}>
          <LogoutIcon />
        </div>
      </div>
    </div>
  );
};

interface QrCodeModalProps {
  open: boolean;
  onClose: () => void;
}

const QrCodeModal: React.FC<QrCodeModalProps> = ({ open, onClose }) => {
  const Styles = useStyles();
  const manager = useUserManager();

  const getQrCodeValue = () => `${manager.entity}/${manager.id}/${localStorage.getItem("MenuPass@TotemHash")}`;

  return (
    <Modal open={open} className={Styles.modal}>
      <div className={Styles.modalView}>
        <div className={Styles.close} onClick={onClose}>
          <Close />
        </div>

        <QRCode value={getQrCodeValue()} size={195} />

        <Typography component="span">
          Use seu celular para escanear o QR-Code
        </Typography>
      </div>
    </Modal>
  );
};

interface FacilaRecognitionModalProps {
  open: boolean;
  onClose: () => void;
  onFaceLogin: (image: string) => void;
  faceCheckLoading: boolean
}

const FacialRecognitionModal: React.FC<FacilaRecognitionModalProps> = ({
  open,
  onClose,
  onFaceLogin,
  faceCheckLoading
}) => {
  const Styles = useStyles();

  return (
    <Modal open={open} className={Styles.modal}>
      <div className={Styles.modalWebCam}>
        <div className={Styles.closeFace} onClick={onClose}>
          <Close />
        </div>
        <FaceRecognition onSubmit={onFaceLogin} onClose={onClose} faceCheckLoading={faceCheckLoading} />
      </div>
    </Modal>
  );
};
