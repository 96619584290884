import React from "react";
import { Modal, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { Progress } from "../../Progress";
import { Button } from "../..";

export interface LoadingModalProps {
  open: boolean;
  progress: number;
  message?: never;
  showButton?: boolean;
  onClick?: () => void;
}

export interface AlertModalProps {
  open: boolean;
  message: string;
  progress?: never;
  showButton?: boolean;
  onClick?: () => void;
}

export const LoadingModal: React.FC<LoadingModalProps | AlertModalProps> = ({
  open,
  progress,
  message,
  showButton,
  onClick,
}) => {
  const Styles = useStyles();

  return (
    <Modal className={Styles.modal} open={open}>
      <div className={Styles.view}>
        <Typography component="span" align="center">
          {progress && !message ? "Carregando" : message}
        </Typography>

        {showButton && (
          <Button color="secondary" size="large" fullWidth onClick={onClick}>
            ok
          </Button>
        )}

        {progress && <Progress percentage={progress} />}
      </div>
    </Modal>
  );
};
