import { makeStyles, Theme } from "@material-ui/core/styles";

interface DynamicStylesProps {
  containsLabel?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  switchTabView: {
    paddingRight: ({ containsLabel }: DynamicStylesProps) => (containsLabel ? 0 : 36),
    paddingLeft: ({ containsLabel }: DynamicStylesProps) => (containsLabel ? 0 : 25),
    marginBottom: ({ containsLabel }: DynamicStylesProps) => (containsLabel ? 12 : 0),
  },

  label: {
    width: "100%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
    marginBottom: 5,
  },

  switchTab: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    whiteSpace: "nowrap",
  },

  switchOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ containsLabel }: DynamicStylesProps) =>
      containsLabel ? "center" : "space-between",
    flexShrink: 0,
    width: "50%", // Ajuste conforme necessário
    height: ({ containsLabel }: DynamicStylesProps) => (containsLabel ? 48 : 36),
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: ({ containsLabel }: DynamicStylesProps) => (containsLabel ? 16 : 12),
    backgroundColor: ({ selected }: DynamicStylesProps) =>
      selected ? "#000" : "transparent",
    cursor: "pointer",
    transition: "background-color 0.3s",

    // Adiciona estilo de toque
    "&:hover, &:active, &:focus": {
      backgroundColor: ({ selected }: DynamicStylesProps) =>
        selected ? "#000" : theme.palette.action.hover, // Manter o preto se selecionado
    },

    "& svg": {
      width: 18,
      height: 18,
      marginRight: 8, // Espaçamento entre o ícone e o texto
    },

    "& svg path": {
      stroke: ({ selected, disabled }: DynamicStylesProps) =>
        disabled
          ? theme.palette.secondary.main
          : theme.palette.getContrastText(selected ? "#000" : "#FFF"),
    },

    "& span": {
      fontSize: 12,
      fontWeight: "bold",
      color: ({ selected, disabled }: DynamicStylesProps) =>
        disabled
          ? theme.palette.secondary.main
          : theme.palette.getContrastText(selected ? "#000" : "#FFF"),
    },
  },
}));

export default useStyles;
